
























































import { Component, Prop, Vue } from 'vue-property-decorator';

export default Vue.extend({
    props: {
      formData: Object,
      formType: { type: String, default: '註冊' },
    },
    methods: {
        isRegister() {
            return (this.$props.formType === '註冊');
        },
    },
});
