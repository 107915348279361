






































import { Component, Prop, Vue } from 'vue-property-decorator';
import ApplicantInfo from '@/components/ApplicantInfo.vue';
import { register, getSMSToken } from '@/scripts/api/login';
import { errHandle, showMessage } from '@/scripts/utils/library';

const TIME_COUNT = 60;

export default Vue.extend({
    components: {
        ApplicantInfo,
    },
    data() {
        return {
            formData: {
                email: '',
                mobile: '',
                password: '',
                confirm_password: '',
                sms_token: '',
                gender: 'Mr',
                full_name: '',
                from_channel: '',
            },
            formRules: {
                name: [
                    { required: true, message: '請輸入姓名', trigger: ['blur'] },
                ],
                mobile: [
                    { required: true, len: 8, pattern: /^[0-9]+$/, message: '請輸入8位數字香港手提電話號碼', trigger: ['blur'] },
                ],
                password: [
                    { required: true, min: 8, message: '請輸入密碼 (至少8個字)', trigger: ['blur'] },
                ],
                confirm_password: [
                    { required: true, min: 8, message: '請輸入確認密碼', trigger: ['blur'] },
                ],
                sms_token: [
                    { required: true, message: '請輸入SMS 短訊驗證碼', trigger: ['blur'] },
                ],
            },
            submitting: false,
            gettingCode: false,
            count: TIME_COUNT,
            timer: null as any,
        };
    },
    props: {
        formType: { type: String, default: '註冊' },
    },
    methods: {
        getCode() {
            if (!this.timer) {
                (this.$refs.form as any).validateField('mobile', (validateError: any) => {
                    // console.debug('valid field dump');
                    // console.dir(valid);
                    if (validateError) {
                        errHandle(this, validateError);
                    } else {
                        getSMSToken(this.formData.mobile, this.formType, 'R-').then((response) => {
                            console.debug('get sms token success');
                            this.count = TIME_COUNT;
                            this.gettingCode = true;
                            this.timer = setInterval(() => {
                                if (this.count > 0 && this.count <= TIME_COUNT) {
                                    this.count--;
                                } else {
                                    this.gettingCode = false;
                                    clearInterval(this.timer);
                                    this.timer = null;
                                }
                            }, 1000);
                        }).catch((error) => {
                            console.debug('get sms token submit error:' + error);
                        }).finally(() => {
                            // do nothing
                        });
                    }
                });
            }
        },
        onSubmit() {
            (this.$refs.form as any).validate((validate: boolean, failedFields: object) => {
                if (validate) {
                    if (this.formData.password === this.formData.confirm_password) {
                        // valid ok
                        console.debug('valid ok');
                        const commandUrl = (this.formType === '註冊' ? '/register' : '/reset-pwd');
                        this.submitting = true;
                        register(this.formData, commandUrl).then((response) => {
                            // console.debug('submit success');
                            // console.dir(response);
                            showMessage(this, this.formType + '成功');
                            (this.$refs.form as any).resetFields();
                            this.count = 0;
                            const payload = {loginSess: response.data.login_session, mobile: this.formData.mobile};
                            this.$store.dispatch('logined', payload);
                            this.$router.push('/loan-app');
                            console.debug('register completed');
                        }).catch((error) => {
                            // console.debug('submit error');
                            // console.dir(error);
                            errHandle(this, error);
                            // if (error.errorCode == 'Error.InvalidToken')
                            // {
                            //     this.count = 0;
                            // }
                        }).finally(() => {
                            // console.debug('submit finally');
                            this.submitting = false;
                        });
                    } else {
                        console.debug('Password:' + this.formData.password
                                        + ',confirm pwd=' + this.formData.confirm_password);
                        errHandle(this, '密碼與確認密碼必須相同');
                    }
                } else {
                    errHandle(this, failedFields);
                    return false;
                }
            });
        },
    },
});
